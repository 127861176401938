<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="750px" top="10vh" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm" label-width="120px">
                    <el-form-item label="产品主：" prop="productId">
                        <span >{{m.productTitle}}</span>
                    </el-form-item>
                    <el-form-item label="物料名称：" prop="name">
                        <el-input v-model="m.name"></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <input-enum enumName="taskStateEnum" v-model="m.state"></input-enum>
                    </el-form-item>
                    <el-form-item label="类型">
                        <input-enum enumName="productTypeEnum" v-model="m.category"></input-enum>
                    </el-form-item>
                    <el-form-item label="研发负责人：" prop="userName">
                        <el-input v-model="m.userName"></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input type="textarea" rows="5" placeholder="备注" v-model="m.info"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">确定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
export default {
    components: { inputEnum },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {
                productId: [
                    {
                        required: true,
                        message: "请输入产品主id",
                        trigger: "blur"
                    }
                ],
                name: [
                    {
                        required: true,
                        message: "请输入产品名称",
                        trigger: "blur"
                    }
                ],
                state: [],
                category: [],
                userName: [],
                info: []
            },
            fileList: []
        };
    },
    methods: {
        open: function(data) {
            this.isShow = true;
            if (data) {
                this.title = "修改 产品明细关联";

                this.m = data;
            } else {
                this.m = {
                    productId: data.id,
                    productTitle: data.productTitle,
                    name: "",
                    state: 0,
                    category: 0,
                    userName: "",
                    info: ""
                };
                this.title = "添加 产品明细关联";
            }
        },

        //提交产品明细关联信息
        ok: function(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.sa.post("/productDetail/save", this.m).then(res => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    },
    created() {}
};
</script>